import * as React from 'react';
import { PageProps } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import { MainTemplate } from '../templates/main/main';
import { SEO } from '../components/seo/seo';
import { Feedback } from '../ui/pages/thank-you/feedback/feedback';
import { CountdownTimer } from '../ui/pages/thank-you/countdown-timer/countdown-timer';

const CookiePolicyPage = ({ location }: PageProps) => {
  const intl = useIntl();

  return (
    <MainTemplate>
      <SEO
        location={location}
        title={intl.formatMessage({ id: 'thank_you.seo_title' })}
        description={intl.formatMessage({
          id: 'thank_you.seo_description',
        })}
        robots="noindex,nofollow,noodp"
      />
      <div className="container">
        <Feedback />
        <CountdownTimer />
      </div>
    </MainTemplate>
  );
};

export default CookiePolicyPage;
