import * as React from 'react';
import * as styles from './feedback.module.scss';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import { PageHeadline } from '../../../../components/page-headline/page-headline';

export const Feedback = () => {
  return (
    <div className={styles.wrapper}>
      <PageHeadline>
        <FormattedMessage id="thank_you.headline" />
      </PageHeadline>
      <p>
        <FormattedMessage id="thank_you.feedback" />
      </p>
      <p>
        <FormattedMessage id="thank_you.greeting" />
      </p>
    </div>
  );
};
