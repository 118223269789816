import * as React from 'react';
import * as styles from './countdown-timer.module.scss';
import { useEffect } from 'react';
import { FormattedMessage, navigate } from 'gatsby-plugin-react-intl';
import { ROUTES } from '../../../../const/routes';
import { Routes } from '../../../../enum/Routes';

export const CountdownTimer = () => {
  const [timer, setTimer] = React.useState(10);
  const id = React.useRef<number>();
  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer(time => time - 1);
    }, 1000);
    return () => clear();
  }, []);

  useEffect(() => {
    if (timer === 0) {
      clear();
      navigate(ROUTES[Routes.INDEX]());
    }
  }, [timer]);

  return (
    <div
      className={`${styles.wrapper} d-flex justify-content-center align-items-center`}
    >
      <p>
        <FormattedMessage id="thank_you.redirect_to_home_in" />
        <span>{` ${timer} `}</span>
        <FormattedMessage id="thank_you.seconds" />
      </p>
    </div>
  );
};
